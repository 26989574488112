import React, { memo, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    CircularProgress,
    Grid,
    FormControl,
    NativeSelect,
    InputLabel,
    Typography,
    IconButton,
    Stack,
    TableSortLabel,
    Button
} from '@mui/material';
import { useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SavingsIcon from '@mui/icons-material/Savings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RefreshIcon from '@mui/icons-material/Refresh';
import InvoiceOrientation from '../../Dialog/InvoiceOrientation';
import InvoiceItemsPopUp from './InvoiceItemsPopUp';
import SearchBox from '../../Search/SearchBox';
import configServ from '../../../services/config';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import CustomerVoucherPopup from '../../Voucher/CustomerVoucherPopup';
import useDebounce from '../../hooks/useDebounce';
import '../../Style.css';
import DispatchPopup from '../Dispatch/DispatchPopup';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';

//FUNCTION
const InvoiceTable = ({ handleEditData, isChanged, handleClickOpen, handleDialogOpen }) => {
    //#region code
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { company_type } = useSelector((state) => state.admin);
    const { user_id, company_id, companyName, companyAddress, companyState, companyCountry } = useSelector((state) => state.GlobalVariables);
    const [invoiceList, setInvoiceList] = useState([]);
    const [searchBy, setSearchBy] = useState('partyname');
    const [search, setSearch] = useState(null);
    const debounceSearch = useDebounce(search, 1000);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [printData, setPrintData] = useState({});
    const [count, setCount] = useState(0);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [openInvoiceItems, setOpenInvoiceItems] = useState(false);
    const [openVoucher, setOpenVoucher] = useState(false);
    const [invoice_no, setInvoice_no] = useState(null);
    const [customer_id, setCustomer_id] = useState(null);
    const [openDispatch, setOpenDispatch] = useState(false);
    const [dispatchDetail, setDispatchDetail] = useState(null);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');
    const [refresh, setRefresh] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isDownload, setIsDownload] = useState(false);

    const location = useLocation();
    const [redirectInvoiceNo, setRedirectInvoiceNo] = useState(location.state);

    useEffect(() => {
        if (redirectInvoiceNo) {
            setSearchBy('invoice_number');
            setSearch(redirectInvoiceNo);
            fetchInvoiceList('invoice_number', redirectInvoiceNo);
        }
        else {
            setSearchBy('partyname');
            setSearch('');
        }
    }, [redirectInvoiceNo]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (!redirectInvoiceNo) {
            fetchInvoiceList();
        }
    }, [isChanged, page, rowsPerPage, order, orderBy, refresh]);

    useEffect(() => {
        if (!redirectInvoiceNo) {
            if (page === 0) {
                fetchInvoiceList();
            }
            else {
                setPage(0);
            }
        }
    }, [debounceSearch]);

    const fetchInvoiceList = async (searchByInv = null, InvNo = null) => {
        try {
            setLoading(true);
            const dataToSend = {
                company_id: company_id,
                search_by: searchByInv ? searchByInv : searchBy,
                search: InvNo ? InvNo : debounceSearch,
                per_page: rowsPerPage,
                page: page,
                sort_by: orderBy,
                order,
            }
            const res = await configServ.invoiceListAdmin(dataToSend);
            if (res.status === 200) {
                const result = res.data.map((item, index) => ({
                    sn: index + 1,
                    id: item.id,
                    customer_id: parseInt(item.customer_id),
                    fname: item.partyname,
                    contact_name: item.partyname,
                    partyname: item.partyname,
                    buyeraddress: item.buyeraddress,
                    ShipToAddress: item.ShipToAddress,
                    delivery_note: item.delivery_note,
                    salesperson: item.salesperson,
                    pay_mode: item.pay_mode,
                    invoice_number: item.invoice_number,
                    date: item.date,
                    due_date: item.due_date,
                    tcs_rate: item.tcs_rate,
                    total_amount: CheckAndReturn.roundToInteger(Number(item.total_amount)),
                    dispatch: Boolean(item.dispatch),
                    is_paid: item.is_paid,
                    items: item.items,
                    paid_amount: item.voucher.length === 0 ? null : item.voucher.reduce((sum, i) => sum + Number(i.amount ?? 0), 0),
                }));
                setInvoiceList(result);
                setCount(res.total);
            }
            else {
                setInvoiceList([]);
                setCount(0);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const headers = [
        { id: 'partyname', label: 'NAME' },
        { id: 'invoice_number', label: 'INV NO' },
        { id: 'total_amount', label: 'AMOUNT' },
        { id: 'date', label: 'DATE' },
        { id: 'due_date', label: 'DUE DATE' },
        ...(company_type === "product" ? [{ id: 'dispatch', label: 'DISPATCH' }] : []),
        { id: 'is_paid', label: 'PAID' }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const cellStyle = {
        py: 0,
        my: 0,
    }

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const printInvoice = async (inv) => {
        const data = {
            "id": inv.id,
            "invoice_no": inv.invoice_number,
            "user_id": user_id,
            "company_id": company_id,
        }
        setOpen(true);
        setPrintData(data)
    }

    const handlePrint = (params) => {
        const data = invoiceList.filter(x => x.id === params.id);
        printInvoice(data[0]);
    }

    const handleEdit = (params) => {
        scrollToTop();
        const data = invoiceList.filter(x => x.id === params.id);
        // calculating amount without tax
        data[0].items.map((item) => (
            item.amountWithoutTax = item.qty * item.rate
        ));
        handleEditData(data)
    }

    const handleEOptions = (params) => {
        const data = invoiceList.filter(x => x.id === params.id);
        handleClickOpen(data[0]);
    }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleInvoiceItems = (params) => {
        try {
            if (params.items.length > 0) {
                setInvoiceItems(params.items);
            }
            setOpenInvoiceItems(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleInvoiceItemsClose = () => {
        try {
            setInvoiceItems([]);
            setOpenInvoiceItems(false);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleVoucherOpen = (params) => {
        setCustomer_id(params.customer_id);
        setInvoice_no(params.invoice_number);
    }

    useEffect(() => {
        if (customer_id && invoice_no) {
            setOpenVoucher(true);
        }
    }, [customer_id, invoice_no]);

    const handleVoucherClose = () => {
        setOpenVoucher(false);
        setCustomer_id(null);
        setInvoice_no(null);
    }

    const handleCustomerClick = (params) => {
        try {
            const data = params;
            navigate('/customers', { state: data });
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDispatch = (params) => {
        try {
            const data = params;
            setDispatchDetail(data);
            openDispatchPopup();
        }
        catch (e) {
            console.log(e);
        }
    }

    const openDispatchPopup = () => {
        setOpenDispatch(true);
    }

    const closeDispatchPopup = () => {
        setOpenDispatch(false);
        setRefresh(!refresh);
    }

    const invoiceRef = useRef();

    const handlePrintData = () => {
        setIsPrint(true);
    }

    useEffect(() => {
        if (isPrint === true) {
            handleTablePrint();
            setIsPrint(false);
        }
    }, [isPrint]);

    const handleTablePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: 'Invoices',
    });

    const handleDownloadData = () => {
        setIsDownload(true);
    }

    useEffect(() => {
        if (isDownload === true) {
            handleDownload();
            setIsDownload(false);
        }
    }, [isDownload]);

    const handleDownload = () => {
        try {
            dispatch(triggerLoader());
            const input = invoiceRef.current;

            html2canvas(input, { scale: 1 }) // Adjust the scale to reduce resolution (e.g., 1 for lower resolution)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use 'image/jpeg' and reduce quality (0.75 is 75% quality)
                    const pdf = new jsPDF();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight); // Use 'JPEG' instead of 'PNG'
                    setTimeout(() => {
                        pdf.save('Invoices.pdf');
                        dispatch(triggerLoader());
                    }, 5000);
                })
                .catch(error => {
                    console.error('Error generating PDF: ', error);
                    dispatch(triggerLoader()); // Stop loader if there's an error
                });
        }
        catch (e) {
            console.log(e);
            dispatch(triggerLoader()); // Stop loader if there's an error in try block
        }
    };

    //#endregion

    return (
        <>
            <InvoiceItemsPopUp openInvoiceItems={openInvoiceItems} handleInvoiceItemsClose={handleInvoiceItemsClose} invoiceItems={invoiceItems} />
            <InvoiceOrientation open={open} setOpen={setOpen} data={printData} />

            {/* Customer Vocher */}
            <CustomerVoucherPopup openVoucher={openVoucher} handleVoucherClose={handleVoucherClose} customer_id={customer_id} invoice_no={invoice_no} />

            {/* Dispatch */}
            <DispatchPopup openDispatch={openDispatch} closeDispatchPopup={closeDispatchPopup} dispatchDetail={dispatchDetail} />

            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1} direction={'row'}>
                <Grid container item xs={12} md={3} justifyContent={'flex-start'}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Invoice List: {count}</Typography>
                </Grid>
                <Grid container item xs={12} md={3} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Stack direction={'row'} spacing={2}>
                        <Button
                            variant='contained' onClick={handleDownloadData}
                            startIcon={<DownloadIcon sx={{ ml: 2 }} />} title='Download'
                            sx={{
                                backgroundColor: '#4bcf6b',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#00b92f',
                                    color: 'white',
                                }
                            }}
                        />
                        <Button
                            variant='contained' onClick={handlePrintData}
                            startIcon={<PrintIcon sx={{ ml: 2 }} />} title='Print'
                            sx={{
                                backgroundColor: '#349af1',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#0868F8',
                                    color: 'white',
                                }
                            }}
                        />
                    </Stack>
                </Grid>
                {
                    redirectInvoiceNo ?
                        <>
                            <Grid container item xs={12} md={6} justifyContent={'flex-end'}>
                                <Button variant='contained' title='Referesh' color='success' startIcon={<RefreshIcon sx={{ ml: 1, color: 'white' }} />}
                                    onClick={() => {
                                        setRedirectInvoiceNo(null);
                                    }}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} md={3}>
                                <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Search By
                                        </InputLabel>
                                        <NativeSelect
                                            value={searchBy}
                                            onChange={handleSearchBy}
                                            inputProps={{
                                                name: 'searchBy',
                                                id: 'uncontrolled-native',
                                            }}
                                        >
                                            <option value={'partyname'}>Customer Name</option>
                                            <option value={'invoice_number'}>Invoice Number</option>
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SearchBox search={search} setSearch={setSearch} />
                            </Grid>
                        </>
                }
            </Grid>

            <TableContainer component={Paper} elevation={0} sx={{ py: 1 }} ref={invoiceRef}>
                {
                    (isPrint || isDownload) &&
                    <>
                        <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>{companyName}</Typography>
                        <Typography sx={{ textAlign: 'center' }}>{companyAddress}, {companyState}, {companyCountry}</Typography>
                    </>
                }
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    sx={headerCellStyle}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {
                                (isDownload === false && isPrint === false) &&
                                <TableCell sx={headerCellStyle}>Action</TableCell>
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            loading === false ?
                                (
                                    invoiceList.length > 0 ?
                                        invoiceList.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}
                                                sx={{
                                                    ...rowStyle,
                                                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                                }}
                                            >
                                                <TableCell sx={cellStyle}>
                                                    <Stack direction={'row'} spacing={1}>
                                                        {
                                                            (isPrint === false && isDownload === false) &&
                                                            <VisibilityIcon onClick={() => handleInvoiceItems(row)} sx={{ cursor: 'pointer' }} color='primary' />
                                                        }
                                                        <Typography variant='body1'>{page * rowsPerPage + rowIndex + 1}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={cellStyle}><span className='linkStyle' onClick={() => handleCustomerClick(row.partyname)}>{row.partyname}</span> - <span style={{ color: 'gray' }}>{row.buyeraddress ?? ''}</span></TableCell>
                                                <TableCell sx={cellStyle}>{row.invoice_number}</TableCell>
                                                <TableCell sx={cellStyle}>₹{row.total_amount}</TableCell>
                                                <TableCell sx={cellStyle}>{CheckAndReturn.transformToDate3(row.date)}</TableCell>
                                                <TableCell sx={cellStyle}>{CheckAndReturn.transformToDate3(row.due_date)}</TableCell>
                                                {
                                                    company_type === "product"
                                                    &&
                                                    <TableCell sx={cellStyle}>{row.dispatch ? <span style={{ color: 'green' }}>'Dispatched'</span> : <span style={{ color: 'red' }}>'Pending'</span>}</TableCell>
                                                }
                                                <TableCell sx={cellStyle}>{row.paid_amount === null ? 'N/A' : `₹${row.paid_amount ?? 0}`}</TableCell>
                                                {
                                                    (isDownload === false && isPrint === false) &&
                                                    <TableCell sx={cellStyle}>
                                                        <Stack direction={'row'}>
                                                            <Tooltip title="Print" placement="top">
                                                                <IconButton onClick={() => handlePrint(row)}>
                                                                    <PrintTwoToneIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit" placement="top">
                                                                <IconButton sx={{ cursor: 'pointer' }}>
                                                                    <EditTwoToneIcon onClick={() => handleEdit(row)} sx={{ cursor: 'pointer' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                        <Stack direction={'row'}>
                                                            {
                                                                company_type === "product" &&
                                                                <Tooltip title='Dispatch' arrow>
                                                                    <IconButton sx={{ cursor: 'pointer' }} disabled={row.dispatch}>
                                                                        <LocalShippingIcon onClick={() => handleDispatch(row)} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                            <Tooltip title="Pay" placement="top">
                                                                <IconButton sx={{ cursor: 'pointer' }}>
                                                                    <SavingsIcon color="success" onClick={() => handleVoucherOpen(row)} sx={{ cursor: 'pointer' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <Box textAlign={'center'}>
                                                    <Typography variant='body1' color={'red'}>No data found.</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                        <div>
                                            <CircularProgress />
                                        </div>
                                    </TableCell>
                                </TableRow>
                        }

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 1 * emptyRows }}>
                                <TableCell colSpan={headers.length + 2} />
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};

export default memo(InvoiceTable);
