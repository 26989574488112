import React, { useEffect, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Link,
    IconButton,
    Grid,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Box,
    Stack,
    InputAdornment,
} from "@mui/material";
import { Autocomplete } from "@mui/joy";
import AddIcon from '@mui/icons-material/Add';
import configServ from '../../../services/config';
import MoneyIcon from '@mui/icons-material/Money';


const AddInvoiceToPay = ({ invoicelist, handleAddItem, delItem, data, isDel, handleInvoiceToPay, handleAmountToPay }) => {


    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [formData, setFormData] = useState({})


    const fetchInvoiceById = async (id) => {
        try {
            const result = await configServ.getInvoiceById({ "inv_id": id });
            // console.log(result)
            const selected = invoicelist.filter((item) => (result.invoice.id === item.id))
            // console.log(selected[0].balance)
            const finalData = {
                ...result,
                balance: selected[0].balance
            }
            setInvoiceDetails(finalData)
            handleInvoiceToPay(finalData, data.id)
        } catch (err) {
            console.log(err)
        }
    }


    const invoiceOnchangeHandler = (e, value) => {
        // console.log(value)
        if (value !== null) {
            fetchInvoiceById(value.id)
        } else {
            setInvoiceDetails([])
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
        if (name === 'amountToPay') {
            handleAmountToPay(value,data.id)
        }
    }

    const payFullAmount = () => {
        setFormData((state) => ({
            ...state,
            amountToPay: invoiceDetails.balance
        }))
    }
    useEffect(()=>{
        const e ={
            target:{
                name:'amountToPay',
                value:formData.amountToPay
            }
        }
        handleChange(e)
    },[formData.amountToPay])


    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-around'}
            alignItems={'center'}
            spacing={2}
        >
            <Autocomplete
                placeholder="Select Invoice(s)"
                options={invoicelist || ['Loading...']}
                sx={{
                    width: '100%',
                }}
                onChange={invoiceOnchangeHandler}
            />
            <TextField
                label="Invoice Due Amount"
                size="small"
                fullWidth
                aria-readonly
                // value={invoiceDetails.invoice?.total_amount || ''}
                value={invoiceDetails.balance || ''}
            />
            {/* <TextField
                label="Amount to Pay"
                size="small"
                fullWidth
                aria-readonly
                name='amountToPay'
            /> */}
            <TextField
                label="Amout To Pay"
                type='number'
                size="small"
                name='amountToPay'
                fullWidth
                onChange={handleChange}
                value={formData.amountToPay || ''}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <Tooltip title="Pay full amount" arrow>
                            <IconButton onClick={payFullAmount}>
                                <MoneyIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>,
                }}
            />
            <Stack
                direction={'row'}
                spacing={0.5}
            >
                <Tooltip title='Add'>
                    <IconButton sx={{ border: '1px solid #55555533' }} onClick={handleAddItem}>
                        <AddIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Remove'>
                    {isDel && <IconButton sx={{ border: '1px solid #55555533' }} onClick={() => { delItem(data.id) }}>
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                    }
                </Tooltip>
            </Stack>
        </Stack>
    )
}

export default AddInvoiceToPay;
