import React, { memo, useEffect, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    CircularProgress,
    Grid,
    FormControl,
    NativeSelect,
    InputLabel,
    Typography,
    IconButton,
    Stack,
    TableSortLabel,
    Button,
    TextField,
    Menu,
    FormControlLabel,
    Checkbox,
    MenuItem
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import '../Style.css';
import SearchBox from '../Search/SearchBox';
import CheckAndReturn from '../../utility/CheckAndReturn';
import configServ from '../../services/config';
import { useSelector } from 'react-redux';
import useDebounce from '../hooks/useDebounce';
import { useReactToPrint } from 'react-to-print';
import DownloadGstInvoiceReport from '../Download/DownloadGstInvoiceReport';

const GstInvoiceReport = () => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [count, setCount] = useState(0);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [searchBy, setSearchBy] = useState('fname');
    const [search, setSearch] = useState(null);
    const debounceSearch = useDebounce(search, 1000);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPrint, setIsPrint] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [filteredReport, setFilteredReport] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchGstInvoiceReport = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                company_id: company_id,
                search: search,
                page: page,
                per_page: rowsPerPage,
                order_by: orderBy,
                order: order,
                start_date: startDate,
                end_date: endDate
            }
            const res = await configServ.getGstInvoiceReport(dataToSend);
            if (res.status === 200) {
                const data = res.data.map((item) => ({
                    gstin: item.gstin,
                    fname: item.fname,
                    invoice_number: item.invoice_number,
                    date: CheckAndReturn.transformToDate3(item.date),
                    total_amount: CheckAndReturn.roundToInteger(item.total_amount),
                    taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),
                    cgst: item.tax_type === 'CGST+SGST' ? (CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2) : 0,
                    sgst: item.tax_type === 'CGST+SGST' ? (CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2) : 0,
                    igst: item.tax_type === 'IGST' ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) : 0
                }));
                setInvoiceList(data);
                setCount(res.total);
            }
            else {
                setInvoiceList([]);
                setCount(0);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchGstInvoiceReport();
    }, [page, rowsPerPage, order, orderBy, startDate, endDate]);

    useEffect(() => {
        if (page === 0) {
            fetchGstInvoiceReport();
        }
        else {
            setPage(0);
        }
    }, [debounceSearch]);

    const headers = [
        { id: 'gstin', label: 'GSTIN' },
        { id: 'fname', label: 'PARTY NAME' },
        { id: 'invoice_number', label: 'BILL NO' },
        { id: 'date', label: 'DATE' },
        { id: 'total_amount', label: 'INVOICE VALUE' },
        { id: 'taxable_amt', label: 'TAXABLE VALUE' },
        { id: 'Total_TaxAmount', label: 'CGST' },
        { id: 'Total_TaxAmount', label: 'SGST' },
        { id: 'Total_TaxAmount', label: 'IGST' }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const cellStyle = {
        py: 0,
        my: 0,
    }

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleDownloadClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadData = async () => {
        try {
            const dataToSend = {
                company_id: company_id,
                search: filteredReport === false ? null : search,
                page: filteredReport === false ? null : page,
                per_page: filteredReport === false ? null : rowsPerPage,
                order_by: filteredReport === false ? 'date' : orderBy,
                order: filteredReport === false ? 'asc' : order,
                start_date: filteredReport === false ? null : startDate,
                end_date: filteredReport === false ? null : endDate
            }
            const res = await configServ.getGstInvoiceReport(dataToSend);
            if (res.status === 200) {
                const data = res.data.map((item) => ({
                    gstin: item.gstin,
                    fname: item.fname,
                    invoice_number: item.invoice_number,
                    date: CheckAndReturn.transformToDate3(item.date),
                    total_amount: CheckAndReturn.roundToInteger(item.total_amount),
                    taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),
                    cgst: item.tax_type === 'CGST+SGST' ? (CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2) : 0,
                    sgst: item.tax_type === 'CGST+SGST' ? (CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2) : 0,
                    igst: item.tax_type === 'IGST' ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) : 0
                }));
                await DownloadGstInvoiceReport(data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSearchBy = (event) => {
        setSearchBy(event.target.value);
    }

    const gstInvoiceReportRef = useRef();

    const handlePrintData = () => {
        setIsPrint(true);
    }

    useEffect(() => {
        if (isPrint === true) {
            handleTablePrint();
            setIsPrint(false);
        }
    }, [isPrint]);

    const handleTablePrint = useReactToPrint({
        content: () => gstInvoiceReportRef.current,
        documentTitle: 'Gst Invoice Report',
    });

    const handleStartDate = (event) => {
        try {
            const date = event.target.value;
            setStartDate(date);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleEndDate = (event) => {
        try {
            const date = event.target.value;
            setEndDate(date);
        }
        catch (e) {
            console.log(e);
        }
    }

    //#endregion

    return (
        <>
            <Grid container item xs={12} md={3} justifyContent={'center'}>
                <Typography sx={{ fontSize: '22px', fontWeight: 'bold', color: '#1e61b4' }}>GST Invoice Report</Typography>
            </Grid>

            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1} direction={'row'}>
                <Grid container item xs={12} md={2} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Stack direction={'row'} spacing={2}>
                        <Button
                            variant='contained' onClick={handleDownloadClick}
                            startIcon={<DownloadIcon sx={{ ml: 2 }} />} title='Download'
                            sx={{
                                backgroundColor: '#4bcf6b',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#00b92f',
                                    color: 'white',
                                }
                            }}
                        />
                        <Button
                            variant='contained' onClick={handlePrintData}
                            startIcon={<PrintIcon sx={{ ml: 2 }} />} title='Print'
                            sx={{
                                backgroundColor: '#349af1',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#0868F8',
                                    color: 'white',
                                }
                            }}
                        />
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleDownloadClose}
                        >
                            <MenuItem>
                                <FormControlLabel control={<Checkbox checked={filteredReport} onClick={() => setFilteredReport(!filteredReport)} />} label="With Filter" /></MenuItem>
                            <MenuItem onClick={handleDownloadData}>Download Excel</MenuItem>
                        </Menu>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={5} justifyContent={'center'}>
                    <TextField
                        size='small'
                        type='date'
                        label='From'
                        InputLabelProps={{ shrink: true }}
                        onChange={handleStartDate}
                    />
                    <TextField
                        size='small'
                        type='date'
                        label='To'
                        InputLabelProps={{ shrink: true }}
                        onChange={handleEndDate}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                        <FormControl
                            fullWidth
                        >
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Search By
                            </InputLabel>
                            <NativeSelect
                                value={searchBy}
                                onChange={handleSearchBy}
                                inputProps={{
                                    name: 'searchBy',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={'fname'}>Customer</option>
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <SearchBox search={search} setSearch={setSearch} />
                </Grid>
            </Grid>

            <TableContainer component={Paper} elevation={0} sx={{ py: 1 }}>
                <Table stickyHeader ref={gstInvoiceReportRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    sx={headerCellStyle}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {/* {
                                (isDownload === false && isPrint === false) &&
                                <TableCell sx={headerCellStyle}>Action</TableCell>
                            } */}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            loading === false ?
                                (
                                    invoiceList.length > 0 ?
                                        invoiceList.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}
                                                sx={{
                                                    ...rowStyle,
                                                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                                }}
                                            >
                                                <TableCell sx={cellStyle}>
                                                    <Stack direction={'row'} spacing={1}>
                                                        <Typography variant='body1'>{page * rowsPerPage + rowIndex + 1}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={cellStyle}>{row.gstin}</TableCell>
                                                <TableCell sx={cellStyle}>{row.fname}</TableCell>
                                                <TableCell sx={cellStyle}>{row.invoice_number}</TableCell>
                                                <TableCell sx={cellStyle}>{row.date}</TableCell>
                                                <TableCell sx={cellStyle}>₹{row.total_amount}</TableCell>
                                                <TableCell sx={cellStyle}>₹{row.taxable_amt}</TableCell>
                                                <TableCell sx={cellStyle}>
                                                    {row.cgst}
                                                </TableCell>
                                                <TableCell sx={cellStyle}>
                                                    {row.sgst}
                                                </TableCell>
                                                <TableCell sx={cellStyle}>
                                                    {row.igst}
                                                </TableCell>
                                                {/* {
                                            (isDownload === false && isPrint === false) &&
                                            <TableCell sx={cellStyle}>
                                                <Stack direction={'row'}>
                                                    <VisibilityIcon />
                                                </Stack>
                                            </TableCell>
                                        } */}
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <Box textAlign={'center'}>
                                                    <Typography variant='body1' color={'red'}>No data found.</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                        <div>
                                            <CircularProgress />
                                        </div>
                                    </TableCell>
                                </TableRow>
                        }

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 1 * emptyRows }}>
                                <TableCell colSpan={headers.length + 2} />
                            </TableRow>
                        )}

                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}

export default GstInvoiceReport
