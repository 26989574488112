import React, { useState, useEffect } from "react";
import CustomersTable from "../../Components/Card/Customers/CustomersTable";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import CustomerCard from "./CustomerCard";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

//FUNCTION
const Customers = () => {
    //#region code
    const navigate = useNavigate();
    const { isLogin } = useSelector((state) => state.GlobalVariables);

    if (isLogin === false) {
        navigate('/admin-login');
    }

    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion

    return (
        <>
            <CustomerCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
            <CustomersTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}
export default Customers