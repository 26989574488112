import React, { memo, useEffect, useState } from 'react';
import { Box, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useNavigate } from 'react-router-dom';

const SupplierLedgerCard = ({ supplierLedger, selectedSupplier }) => {
    //#region code
    const navigate = useNavigate();
    const [purchaseAmount, setPurchaseAmount] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [receivedAmount, setReceivedAmount] = useState(0);

    useEffect(() => {
        let totalPurchaseAmount = 0;
        let totalPaidAmount = 0;
        let totalReceivedAmount = 0;

        supplierLedger.forEach(row => {
            totalPurchaseAmount += Number(row.type === 'purchase' ? row.amount ?? 0 : 0);
            totalPaidAmount += Number(row.type === 'payment' ? row.amount ?? 0 : 0);
            totalReceivedAmount += Number(row.type === 'receipt' ? row.amount ?? 0 : 0);
        });

        setPurchaseAmount(CheckAndReturn.roundToInteger(totalPurchaseAmount));
        setPaidAmount(CheckAndReturn.roundToInteger(totalPaidAmount));
        setReceivedAmount(CheckAndReturn.roundToInteger(totalReceivedAmount));

        return () => {
            setPurchaseAmount(0);
            setPaidAmount(0);
            setReceivedAmount(0);
        }
    }, [supplierLedger]);

    const LinkStyle = {
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            color: 'darkblue',
            textDecoration: 'underline',
        },
    };

    const handlePurchaseClick = (params) => {
        try {
            navigate('/transaction/purchase-bill', { state: params });
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleVoucherClick = (params) => {
        try {
            navigate('/transaction/create-vouchers', { state: { voucher_no: params, entity: 'supplier' } });
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <Box flex={1} textAlign="center">
                <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                    {selectedSupplier ? selectedSupplier.fname : <CircularProgress />}
                </Typography>
            </Box>
            {
                supplierLedger.map((item, index) => (
                    <Card key={index} sx={{ marginBottom: '16px', padding: '16px', backgroundColor: '#f9f9ff' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Date:</Typography>
                                        <Typography variant="body1">{CheckAndReturn.convertDateFormat(item.type === 'purchase' ? item.billing_date ?? '' : item.transaction_date ?? '')}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Type:</Typography>
                                        <Typography variant="body1" sx={LinkStyle}>
                                            {item.type === 'purchase' ? (
                                                <>
                                                    Purchase from(
                                                    <span onClick={() => handlePurchaseClick(item.purchase_bill_no)}>
                                                        {item.purchase_bill_no ?? ''}
                                                    </span>
                                                    )
                                                </>
                                            ) : (
                                                <span onClick={() => handleVoucherClick(item.voucher_no)}>
                                                    {item.type === 'receipt'
                                                        ? `Received from(${item.voucher_no ?? ''})`
                                                        : `Payment(${item.voucher_no ?? ''})`}
                                                </span>
                                            )}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Amount:</Typography>
                                        <Typography variant="body1">₹{item.amount}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))
            }

            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Purchase Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{purchaseAmount}
                </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Paid Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{paidAmount}
                </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Received Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{receivedAmount}
                </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Due Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{purchaseAmount - paidAmount + receivedAmount}
                </Typography>
            </Stack>
        </>
    );
};

export default memo(SupplierLedgerCard);
