import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Box, InputAdornment, Grid, Typography, FormControl, InputLabel, NativeSelect, TextField, CircularProgress, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import SearchBox from '../../../Search/SearchBox';
import configServ from '../../../../services/config';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import useDebounce from '../../../hooks/useDebounce';
import { Visibility } from '@mui/icons-material';
import PurchaseOrderItemsPopup from './PurchaseOrderItemsPopup';
import { useNavigate } from 'react-router-dom';

export default function PurchaseOrderTable({ handleEditData, isChanged }) {
  //#region code
  const navigate = useNavigate();
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [searchBy, setSearchBy] = useState('supplier');
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openPurchaseOrderItems, setOpenPurchaseOrderItems] = useState(false);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);

  useEffect(() => {
    fetchPurchaseOrderList();
  }, [isChanged, page, rowsPerPage]);

  useEffect(() => {
      if (page === 0) {
        fetchPurchaseOrderList();
      }
      else {
        setPage(0);
      }
  }, [debounceSearch]);

  const fetchPurchaseOrderList = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        user_id: user_id,
        company_id: company_id,
        page: page,
        per_page: rowsPerPage,
        search_by: searchBy,
        search: debounceSearch,
      };
      const res = await configServ.purchaseOrderList(dataToSend);
      if (res.status === 200) {
        setPurchaseOrderList(res.data);
        setCount(res.total);
      }
      else{
        setPurchaseOrderList([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const headers = ['SUPPLIER', 'ORDER NO', 'TOTAL AMOUNT', 'DELIVERY DATE', 'ACTION'];

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5', // Set the header background color to blue
    color: 'white',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleEdit = (params) => {
    handleEditData(params);
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const handlePurchaseItems = async (params) => {
    try {
      const dataToSend = {
        id: params,
        company_id: company_id,
      }
      const res = await configServ.getPurchaseOrderItems(dataToSend);
      if (res.status === 200) {
        setPurchaseOrderItems(res.data);
        setOpenPurchaseOrderItems(true);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSupplierClick = (params) => {
    try {
      const data = params;
      navigate('/supplier', { state: data });
    }
    catch (e) {
      console.log(e);
    }
  }

  const closePurchaseOrderItems = () => {
    setOpenPurchaseOrderItems(false);
    setPurchaseOrderItems([]);
  }
  //#endregion

  return (
    <>
      <PurchaseOrderItemsPopup openPurchaseOrderItems={openPurchaseOrderItems} closePurchaseOrderItems={closePurchaseOrderItems} purchaseOrderItems={purchaseOrderItems} />

      <Paper sx={{ width: '100%' }}>
        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Purchase Orders: {count}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
              <FormControl
                fullWidth
              >
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={'contact_name'}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: 'searchBy',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'supplier'}>Supplier</option>
                  <option value={'purchase_order_no'}>Purchase Order No</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
            <SearchBox search={search} setSearch={setSearch} />
          </Grid>
        </Grid>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ?
              <>
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                    <Box>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </>
              :
              <>
                <TableBody>
                  {purchaseOrderList.map((row, index) => {
                      return (
                        <TableRow key={row.id}
                          sx={{
                            ...rowStyle,
                            ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell><span className='linkStyle' onClick={() => handleSupplierClick(row.supplier?row.supplier.fname:'')}>{row.supplier?row.supplier.fname:''}</span></TableCell>
                          <TableCell>{row.purchase_order_no}</TableCell>
                          <TableCell sx={{ color: 'green' }}>₹{row.total_amount}</TableCell>
                          <TableCell>{CheckAndReturn.transformToDate2(row.delivery_date)}</TableCell>
                          <TableCell>
                            <Stack direction={'row'}>
                              <EditIcon color='primary'
                                sx={{
                                  borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                  padding: 0, ":hover": { backgroundColor: '#99999955' }
                                }}
                                onClick={() => handleEdit(row)} />
                              <Visibility
                                sx={{
                                  borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                  padding: 0, ":hover": { backgroundColor: '#99999955' }
                                }}
                                onClick={() => handlePurchaseItems(row.id)} />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </>
  );

}