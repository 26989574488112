import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import InvoiceOrientation from '../../Dialog/InvoiceOrientation';
import { useSelector } from 'react-redux';

export default function WantToPrint({ open, setOpen, data }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [printOpen, setPrintOpen] = useState(false);
    const [printData, setPrintData] = useState({});

    const printInvoice = async () => {
        const dataToSend = {
            "id": data.id,
            "invoice_no": data.invoice_number,
            "user_id": user_id,
            "company_id": company_id,
        }
        setPrintOpen(true);
        setPrintData(dataToSend)
    };

    const handleClose = () => {
        setOpen(false);
    };
    //#endregion

    return (
        <div>
            <InvoiceOrientation open={printOpen} setOpen={setPrintOpen} data={printData} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='body' fontSize={'large'}>Print?</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography >Want to print the invoice</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => { printInvoice(); handleClose() }}>
                        Yes
                    </Button>
                    <Button variant='outlined' onClick={() => { handleClose(); }} >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}