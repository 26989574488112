import React, { memo, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Box, InputAdornment, Grid, Typography, FormControl, InputLabel, NativeSelect, TextField, CircularProgress, Stack, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import configServ from '../../../../services/config';
import { useSelector } from 'react-redux';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import PurchaseBillItemsPopup from './PurchaseBillItemsPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import SearchBox from '../../../Search/SearchBox';

//FUNCTION
const PurchaseBillTable = ({ handleEditData, isChanged }) => {
  //#region code
  const navigate = useNavigate();
  const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [purchaseBillList, setPurchaseBillList] = useState([]);
  const [searchBy, setSearchBy] = useState('purchase_bill_no');
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openPurchaseBillItems, setOpenPurchaseBillItems] = useState(false);
  const [purchaseBillItems, setPurchaseBillItems] = useState([]);
  const location = useLocation();
  const [redirectVoucherNo, setRedirectVoucherNo] = useState(location.state);

  useEffect(() => {
    if (redirectVoucherNo) {
      //setSearchBy('purchase_bill_no');
      setSearch(redirectVoucherNo);
      fetchPurchaseBillList(redirectVoucherNo);
    }
    else {
      //setSearchBy('purchase_bill_no');
      setSearch('');
    }
  }, [redirectVoucherNo]);

  useEffect(() => {
    if (!redirectVoucherNo) {
      fetchPurchaseBillList();
    }
  }, [isChanged, page, rowsPerPage]);

  useEffect(() => {
    if (!redirectVoucherNo) {
      if (page === 0) {
        fetchPurchaseBillList();
      }
      else {
        setPage(0);
      }
    }
  }, [debounceSearch]);

  const fetchPurchaseBillList = async (redirectVoucherNo) => {
    try {
      const dataToSend = {
        "is_super": is_super,
        "user_id": user_id,
        "company_id": company_id,
        "search_by": searchBy,
        "search": redirectVoucherNo ? redirectVoucherNo : debounceSearch,
        "per_page": rowsPerPage,
        "page": page
      }
      setLoading(true);
      const res = await configServ.getPurchaseBills(dataToSend);
      if (res.status === 200) {
        setPurchaseBillList(res.data);
        setCount(res.total);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const headers = ['SUPPLIER', 'BILL NO', 'ORDER NO', 'AMOUNT', 'DUE DATE', 'ACTION'];

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5', // Set the header background color to blue
    color: 'white',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      }
      else {
        setSearch(data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleEdit = (params) => {
    const data = purchaseBillList.filter((x) => x.id === params);
    handleEditData(data);
  };

  const handlePurchaseItems = async (params) => {
    try {
      const dataToSend = {
        id: params,
        company_id: company_id,
      }
      const res = await configServ.getPurchaseBillItems(dataToSend);
      if (res.status === 200) {
        setPurchaseBillItems(res.data);
        setOpenPurchaseBillItems(true);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handlePurchaseBillItemsClose = () => {
    setOpenPurchaseBillItems(false);
    setPurchaseBillItems([]);
  }

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const handleSupplierClick = (params) => {
    try {
      const data = params;
      navigate('/supplier', { state: data });
    }
    catch (e) {
      console.log(e);
    }
  }
  //#endregion

  return (
    <>
      <PurchaseBillItemsPopup openPurchaseBillItems={openPurchaseBillItems} handlePurchaseBillItemsClose={handlePurchaseBillItemsClose} purchaseBillItems={purchaseBillItems} />

      <Paper sx={{ width: '100%' }}>
        <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ mb: 1 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Purchase Bills: {count}</Typography>
          </Grid>
          {
            redirectVoucherNo ?
              <>
                <Grid container item xs={12} md={5} justifyContent={'flex-end'}>
                  <Button variant='contained' title='Referesh' color='success' startIcon={<RefreshIcon sx={{ ml: 1, color: 'white' }} />}
                    onClick={() => {
                      setRedirectVoucherNo(null);
                    }}
                  />
                </Grid>
              </>
              :
              <>
                <Grid item xs={12} md={3}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Search By
                      </InputLabel>
                      <NativeSelect
                        onChange={handleSearchBy}
                        inputProps={{
                          name: 'searchBy',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={'purchase_bill_no'}>Purchase Bill No</option>
                        <option value={'supplier'}>Supplier</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} justifyContent={'flex-end'}>
                  <SearchBox search={search} setSearch={setSearch} />
                </Grid>
              </>
          }

        </Grid>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ?
              <TableRow>
                <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                  <div>
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
              :
              <TableBody>
                {purchaseBillList.length > 0 ?
                  purchaseBillList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={row.id}
                          sx={{
                            ...rowStyle,
                            ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell><span className='linkStyle' onClick={() => handleSupplierClick(row.suppliers.fname)}>{row.suppliers.fname}</span></TableCell>
                          <TableCell>{row.purchase_bill_no}</TableCell>
                          <TableCell>{row.purchase_order_no}</TableCell>
                          <TableCell>₹{row.total_amount}</TableCell>
                          <TableCell>{CheckAndReturn.transformToDate2(row.bill_due_date)}</TableCell>
                          <TableCell>
                            <Stack direction={'row'}>
                              <EditIcon sx={{
                                borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                padding: 0, m: 0.5, ":hover": { backgroundColor: '#99999955' }
                              }} color='primary' onClick={() => handleEdit(row.id)} />
                              <VisibilityIcon sx={{
                                borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                padding: 0, m: 0.5, ":hover": { backgroundColor: '#99999955' }
                              }} color='primary' onClick={() => handlePurchaseItems(row.id)} />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  :
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                      <Box sx={{ color: 'red', fontWeight: 600 }}>
                        No data found.
                      </Box>
                    </TableCell>
                  </TableRow>}
              </TableBody>}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </>
  );
}

export default memo(PurchaseBillTable);