import React, { memo, useEffect, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Grid,
    CircularProgress,
    Button,
    Stack,
    TableSortLabel,
    Menu,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Select,
    FormControl,
    InputLabel,
    Box,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import SavingsIcon from '@mui/icons-material/Savings';
import { useDispatch, useSelector } from 'react-redux';
import configServ from '../../../../services/config';
import DownloadOutstandingExcel from '../../../Download/DownloadOutstandingExcel';
import DownloadOutstandingPdf from '../../../Download/DownloadOutstandingPdf';
import OutstandingPopup from './OutstandingPopup';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import { triggerLoader } from '../../../../redux/reducers/LoaderTrigger';
import SearchBox from '../../../Search/SearchBox';
import SearchByBox from '../../../Search/SearchByBox';
import CustomerVoucherPopup from '../../../Voucher/CustomerVoucherPopup';
import useDebounce from '../../../hooks/useDebounce';
import Constants from '../../../../utility/Constants';
import { useNavigate } from 'react-router-dom';
import bell_notification from '../../../Assets/images/bell_notification.gif';

//FUNCTION
const OutstandingReportTable = () => {
    //#region code
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { company_id, companyName, companyAddress, companyState, companyCountry } = useSelector((state) => state.GlobalVariables);

    const rowsPerPageOptions = [50, 100, 200];
    const [page, setPage] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [outstandingCustomerData, setOutstandingCustomerData] = useState(null);
    const [openOutstandingPopup, setOpenOutstandingPopup] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [count, setCount] = useState(0);
    const [searchBy, setSearchBy] = useState('customer_name');
    const [search, setSearch] = useState(null);
    const debounceSearch = useDebounce(search, 1000);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('customer_name');
    const [openVoucher, setOpenVoucher] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customer_id, setCustomer_id] = useState(null);
    const [filteredReport, setFilteredReport] = useState(false);
    const [filterOsAmount, setFilterOsAmount] = useState(null);
    const [voucherChanged, setVoucherChanged] = useState(false);
    const [isPrint, setIsPrint] = useState(false);

    const searchByArray = [
        { label: 'Name', value: 'customer_name' },
        { label: 'Address', value: 'address' }
    ]

    const handleDownloadClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadPDF = () => {
        downloadPdf();
        handleDownloadClose();
    };

    const handleDownloadExcel = () => {
        downloadExcel();
        handleDownloadClose();
    };

    const headers = [
        { id: 'customer_name', label: 'Name' },
        { id: 'phone', label: 'Phone' },
        { id: 'total_invoice_amount', label: 'Inv Amount' },
        { id: 'total_payment_amount', label: 'Payment' },
        { id: 'total_receipt_amount', label: 'Receipt' },
        { id: 'due_amount', label: 'Due Amount' },
    ];

    const fetchPaymentList = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                company_id,
                search_by: searchBy,
                search: debounceSearch,
                per_page: rowsPerPage,
                page,
                sort_by: orderBy,
                order,
                outstanding_amt: filterOsAmount,
            };
            const res = await configServ.getPaymentList(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setPaymentList(result);
                setCount(res.total);
            }
            else {
                setPaymentList([]);
                setCount(0);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaymentList();
    }, [page, rowsPerPage, order, orderBy, voucherChanged]);

    useEffect(() => {
        if (page === 0) {
            fetchPaymentList();
        }
        else {
            setPage(0);
        }
    }, [debounceSearch, filterOsAmount]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, paymentList.length);

    const headerCellStyle = {
        fontSize: 15,
        fontWeight: 700,
        backgroundColor: '#4CB5F5',
        color: 'white',
        padding: '10px',
        margin: 0,
        //textAlign: 'center',
    };

    const cellStyle = {
        py: 0,
        my: 0,
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const followUpRowStyle = {
        background: '#f7da00',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDownloadReport = async () => {
        try {
            const dataToSend = {
                company_id: company_id,
                search_by: filteredReport === true ? searchBy : null,
                search: filteredReport === true ? debounceSearch : null,
                outstanding_amt: filteredReport === true ? filterOsAmount : null,
            };
            const res = await configServ.getPaymentReport(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                return result;
            }
            else {
                return null;
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const downloadPdf = async () => {
        try {
            const result = await fetchDownloadReport();
            if (result) {
                await DownloadOutstandingPdf(result);
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const downloadExcel = async () => {
        try {
            const result = await fetchDownloadReport();
            if (result) {
                await DownloadOutstandingExcel(result);
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleLedger = async (params) => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                customer_id: params.customer_id,
                company_id: company_id,
            };
            const res = await configServ.getCustomerLedger(dataToSend);
            if (res.status === 200) {
                const data = {
                    name: params.customer_name,
                    address: params.address,
                    customer: null,
                    ledger: res.data
                }
                setOpenOutstandingPopup(true);
                setOutstandingCustomerData(data);
            }
            else {
                setOpenOutstandingPopup(true);
                setOutstandingCustomerData(null);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(triggerLoader());
        }
    };

    const handleOutstandingPopup = () => {
        try {
            if (openOutstandingPopup === true) {
                setOutstandingCustomerData(null);
            }
            setOpenOutstandingPopup(!openOutstandingPopup);
        } catch (e) {
            console.log(e);
        }
    };

    const handleVoucherOpen = (params) => {
        setCustomer_id(params);
        setOpenVoucher(true);
    }

    const handleVoucherClose = () => {
        setOpenVoucher(false);
        setVoucherChanged(!voucherChanged);
    }

    const handleCustomerClick = (params) => {
        try {
            const data = params;
            navigate('/customers', { state: data });
        }
        catch (e) {
            console.log(e);
        }
    }

    const outstandingReportRef = useRef();

    const handlePrintData = () => {
        setIsPrint(true);
    }

    useEffect(() => {
        const printReport = async () => {
            if (isPrint === true) {
                await handlePrint();
                setIsPrint(false);
            }
        };
        printReport();
    }, [isPrint]);

    const handlePrint = useReactToPrint({
        content: () => outstandingReportRef.current,
        documentTitle: 'Cash Vouchers',
    });
    //#endregion

    return (
        <>
            <OutstandingPopup
                openOutstandingPopup={openOutstandingPopup}
                handleOutstandingPopup={handleOutstandingPopup}
                outstandingCustomerData={outstandingCustomerData}
            />

            {/* Customer Voucher Pop up */}
            <CustomerVoucherPopup openVoucher={openVoucher} handleVoucherClose={handleVoucherClose} customer_id={customer_id} />

            {/* Search starts here */}
            <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ pt: 1 }} spacing={1} direction={'row'}>
                <Grid item xs={12} md={2.5} justifyContent={'flex-start'}>
                    <Typography sx={{ fontSize: { xs: '16px', md: '18px' }, fontWeight: 'bold' }}>Outstanding Report: {count}</Typography>
                </Grid>
                <Grid item xs={12} md={2.5} justifyContent={'center'}>
                    <Stack direction={'row'} spacing={1}>
                        <Button
                            variant="contained" title="Download"
                            onClick={handleDownloadClick} startIcon={<DownloadIcon sx={{ marginLeft: 2 }} />}
                            sx={{
                                backgroundColor: '#4bcf6b',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#00b92f',
                                    color: 'white',
                                }
                            }}
                        />
                        <Button
                            variant='contained' onClick={handlePrintData}
                            startIcon={<PrintIcon sx={{ ml: 2 }} />} title='Print'
                            sx={{
                                backgroundColor: '#349af1',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#0868F8',
                                    color: 'white',
                                }
                            }}
                        />
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleDownloadClose}
                        >
                            <MenuItem><FormControlLabel control={<Checkbox checked={filteredReport} onClick={() => setFilteredReport(!filteredReport)} />} label="With Filter" /></MenuItem>
                            <MenuItem onClick={handleDownloadPDF}>Download PDF</MenuItem>
                            <MenuItem onClick={handleDownloadExcel}>Download Excel</MenuItem>
                        </Menu>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={1.5} justifyContent={'center'}>
                    <FormControl fullWidth size='small'>
                        <InputLabel sx={{ alignItems: 'baseline' }}>Dues</InputLabel>
                        <Select
                            fullWidth
                            size={'small'}
                            variant='standard'
                            sx={{ background: 'white', alignItems: 'baseline' }}
                            id='outstanging-report-amount'
                            label='Dues'
                            placeholder='Outstanding Amount'
                            name='outstanding_amount'
                            value={filterOsAmount}
                            onChange={(e) => setFilterOsAmount(e.target.value)}
                        >
                            <MenuItem key={1} value={''}>
                                ALL
                            </MenuItem>
                            {
                                Constants.filterOutstandingAmount.map((item, index) => (
                                    <MenuItem key={index + 2} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2.5}>
                    <SearchByBox searchByArray={searchByArray} handleSearchBy={handleSearchBy} />
                </Grid>
                <Grid item xs={12} md={3} justifyContent={'flex-end'}>
                    <SearchBox search={search} setSearch={setSearch} />
                </Grid>
            </Grid>
            {/* Search ends here */}

            <TableContainer component={Paper} sx={{ py: 1 }} ref={outstandingReportRef}>
                {
                    isPrint &&
                    <>
                        <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>{companyName}</Typography>
                        <Typography sx={{ textAlign: 'center' }}>{companyAddress}, {companyState}, {companyCountry}</Typography>
                    </>
                }
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    sx={headerCellStyle}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {
                                isPrint === false &&
                                <TableCell sx={headerCellStyle}>Action</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading === false ?
                                (
                                    paymentList.length > 0 ?
                                        paymentList.map((row, rowIndex) => (
                                            <TableRow
                                                key={rowIndex}
                                                sx={{
                                                    ...rowStyle,
                                                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                                }}
                                            >
                                                <TableCell sx={cellStyle}>
                                                    <Stack direction={'row'} alignItems={'center'} mx={1}>
                                                        <Typography variant="body1">
                                                            {page * rowsPerPage + rowIndex + 1}
                                                        </Typography>
                                                        {row.entity_id && <img src={bell_notification} alt='' width={30} />}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={cellStyle}>
                                                    <span className='linkStyle' onClick={() => handleCustomerClick(row.customer_name)}>{CheckAndReturn.capitalizeWords(row.customer_name)}</span> - <span style={{ color: 'gray' }}>{CheckAndReturn.capitalizeWords(row.address)}</span>
                                                </TableCell>
                                                <TableCell sx={cellStyle}>{row.phone ?? ''}</TableCell>
                                                <TableCell sx={cellStyle}>₹{CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(row.total_invoice_amount ?? 0))}</TableCell>
                                                <TableCell sx={cellStyle}>₹{CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(row.total_payment_amount ?? 0))}</TableCell>
                                                <TableCell sx={cellStyle}>₹{CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(row.total_receipt_amount ?? 0))}</TableCell>
                                                <TableCell sx={cellStyle}>
                                                    ₹{CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(row.due_amount ?? 0))}
                                                    {(row.due_amount ?? 0) > 0 ? <span style={{ marginLeft: 4, color: 'red' }}>Dr</span> : <span style={{ marginLeft: 4, color: 'green' }}>Cr</span>}
                                                </TableCell>
                                                {
                                                    isPrint === false &&
                                                    <TableCell sx={cellStyle}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            <SavingsIcon color="success" onClick={() => handleVoucherOpen(row.customer_id)} sx={{ cursor: 'pointer' }} />
                                                            <VisibilityIcon color="primary" onClick={() => handleLedger(row)} sx={{ cursor: 'pointer' }} />
                                                        </Stack>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <Box textAlign={'center'}>
                                                    <Typography variant='body1' color={'red'}>No data found.</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                        <div>
                                            <CircularProgress />
                                        </div>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};

export default memo(OutstandingReportTable);
