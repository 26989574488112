import React, { useState, useEffect, memo } from 'react';
import { RadioGroup, Radio, FormLabel, Button, Grid, TextField, Tooltip, FormControlLabel, FormControl, Autocomplete, Typography, Stack, Checkbox, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import configServ from '../../../../services/config';
import Calculation from '../../../../utility/Calculations';
import AddPurchaseOrderItems from '../PurchaseOrder/AddPurchaseOrderItems';
import CardHeadMenu from '../../CardHeadMenu/CardHeadMenu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { triggerLoader } from '../../../../redux/reducers/LoaderTrigger';
import AlertDialog from '../../../AlertDialog/AlertDialog';

//FUNCTION
function PurchaseOrderCard({ editData, setEditData, isChanged, setIsChanged }) {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [approval, setApproval] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [autoPurchaseOrderNo, setAutoPurchaseOrderNo] = useState(true);
    const itemListInitial = [
        {
            id: 1,
            itemname: '',
            itemid: '',
            purchaseoder_id: '',
            hsn_code: '',
            Taxrate: '',
            qty: '',
            rate: '',
            amount: ''
        }
    ];
    const [itemList, setItemList] = useState(itemListInitial);
    const itemListErrorInitial = [{
        itemname: '',
        qty: '',
        rate: '',
    }];
    const [itemListError, setItemListError] = useState(itemListErrorInitial);
    const purchaseOrderInitial = {
        user_id: user_id,
        company_id: company_id,
        _token: '',
        createinv: '',
        partyname: '',
        supplier_id: null,
        id: '',
        billtoaddress: '',
        ShipToAddress: '',
        purchase_order_no: '',
        delivery_date: '',
        salesperson: '',
        pay_terms: '',
        remarks: '',
        order_type: '',
        approval_is: '',
        total_amount: '',
        custId: '',
        item: [
            {
                itemname: '',
                itemid: '',
                purchaseoder_id: '',
                hsn_code: '',
                Taxrate: '',
                qty: '',
                rate: '',
                amount: ''
            }
        ],
    };
    const [purchaseOrder, setPurchaseOrder] = useState(purchaseOrderInitial);
    const purchaseOrderErrorInitial = {
        partyname: '',
        billtoaddress: '',
        ShipToAddress: '',
        purchase_order_no: '',
        delivery_date: '',
        salesperson: '',
    }
    const [purchaseOrderError, setPurchaseOrderError] = useState(purchaseOrderErrorInitial);
    const [purchaseOrderNo, setPurchaseOrderNo] = useState(false);
    const shipToAddressesInitial = [
        {
            id: '',
            address: ''
        }
    ];
    const [shipToAddresses, setShipToAddresses] = useState(shipToAddressesInitial);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    useEffect(() => {
        fetchSupplierList();
        fetchItemList();
    }, []);

    useEffect(() => {
        calculateTotalAmount();
    }, [itemList]);

    const fetchSupplierList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id
            }
            const res = await configServ.getSupplier(dataToSend);
            if (res.status === 200) {
                const result = res.data.filter(x=> x.status === 1);
                setSuppliers(result);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchItemList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    const result = res.data.filter(x => x.status === 1);
                    setItems(result);
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    //Purchase Order code
    // Handle supplier change
    const handleSupplierAutocompleteChange = (event) => {
        try {
            const value = event.target.innerText;
            //const selectedSupplier = suppliers.find((item) => item.fname.toLowerCase() === value.toLowerCase());
            //const id = selectedSupplier.id;

            if (value !== null && value !== undefined) {
                if (value.length > 0) {
                    handleSupplierInputChange({ target: { name: 'partyname', value } });
                }
            }
            // if (id !== null && id !== undefined) {
            //     if (id !== null) {
            //         handleSupplierInputChange({ target: { name: 'supplier_id', id } });
            //     }
            // }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Handle customer input
    const handleSupplierInputChange = (event) => {
        try {
            const SupplierName = event.target.value;
            const selectedSupplier = suppliers.find((item) => item.fname.toLowerCase() === SupplierName.toLowerCase());

            if (selectedSupplier !== null && selectedSupplier !== undefined) {
                setPurchaseOrder((prev) => ({
                    ...prev, // Copy existing fields
                    custId: CheckAndReturn.checkUndefinedReturnNull(selectedSupplier.id), // Update the specific field with the new value
                    partyname: selectedSupplier.fname,
                    supplier_id: selectedSupplier.id,
                    billtoaddress: selectedSupplier.address,
                    ShipToAddress: selectedSupplier.address,
                }));
                //fetchShipToAddress(selectedSupplier.id); 
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Handle Purchase Order Customer
    const handlePurchaseOrderCustomer = (event) => {
        try {
            const { name, value } = event.target;
            setPurchaseOrder((prev) => ({
                ...prev,  //copy existing field values
                [name]: value //update field value
            }))
        }
        catch (error) {
            console.log(error);
        }
    }

    //Fetch Ship To Address
    // const fetchShipToAddress = async (cust_id) => {
    //   try{
    //     const result = await configServ.getShipToAddress({"cust_id": cust_id})
    //     setShipToAddresses(result)
    //   }
    //   catch(error){
    //     console.log(error);
    //   }
    // }

    const handleApprovalChange = (event) => {
        const isApproved = event.target.checked;
        if (isApproved === true) {
            setPurchaseOrder((prev) => ({
                ...prev,
                approval_is: 'on'
            }));
        }
        else {
            setPurchaseOrder((prev) => ({
                ...prev,
                approval_is: ''
            }));
        }
        setApproval(isApproved);
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;
        itemList.forEach(item => {
            // Parse the amount string to a float value
            const amount = parseFloat(item.amount);
            // Check if the amount is a valid number
            if (!isNaN(amount)) {
                // Add the amount to the total
                totalAmount += amount;
            }
        });
        setTotalAmount(totalAmount.toFixed(2));
    };

    // Itemlist code
    // Handle the Autocomplete's onChange event
    const handleAutocompleteChange = (event, id) => {
        const value = event.target.innerText;
        if (value !== null && value !== undefined) {
            if (value.length > 0) {
                handleInputChange({ target: { name: 'itemname', value } }, id);
            }
        }
    };

    // Handle Input Changes
    const handleInputChange = (event, id) => {
        try {
            const { name, value } = event.target;
            let updatedItem;
            if (value !== null && value !== undefined) {
                if (value.length > 0) {
                    if (name === 'itemname') {
                        // Find the selected item from the options
                        const selectedItem = items.find((item) => item.item_name.toLowerCase() === value.toLowerCase());
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                itemid: selectedItem.id.toString(),
                                itemname: selectedItem.item_name,
                                itemcode: selectedItem.item_code,
                                packagingunit: selectedItem.packagingunit,
                                hsn_code: selectedItem.hsncode,
                                qty: '',
                                rate: selectedItem.rate_unit,
                                Taxrate: selectedItem.Taxrate,
                                amount: '0.00'
                            };
                        } else {
                            // If the selected item is not found, reset the properties
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                itemid: '',
                                itemname: value,
                                itemcode: '',
                                packagingunit: '',
                                hsn_code: '',
                                discount: '',
                                rate: '',
                                Taxrate: '',
                            };
                        }
                    }
                    else {
                        // For other fields, just update the value
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            [name]: value,
                        };
                    }
                    // Update the itemList state with the updated item
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    // Function to add a new item to the list
    const handleAddItem = () => {
        setItemList([
            ...itemList,
            { id: itemList.length + 1, name: '', qty: '' },
        ]);
    };

    // Function to delete an item from the list based on its ID
    const handleDeleteItem = (id) => {
        const updatedItemList = itemList.filter((item) => item.id !== id);
        setItemList(updatedItemList);
    };

    // Handle Quantity Change
    const handleQuantityChange = (event, id) => {
        try {
            const { name } = event.target;
            var value = event.target.value;
            let updatedItem;
            if (value !== null && value !== undefined) {
                // Find the selected item from the options
                if (value === "") {
                    value = "0";
                }
                const selectedItem = itemList.find((item) => item.id === id);
                if (value.length > 0) {
                    if (name === 'qty') {
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            const totalAmount = Calculation.GetPurchaseBillItemAmount(value, selectedItem.rate, selectedItem.Taxrate);
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                qty: value === "0" ? '' : value,
                                amount: totalAmount.toString()
                            };
                        }
                        // else {
                        //     // If the selected item is not found, reset the properties
                        //     updatedItem = {
                        //         ...itemList.find((item) => item.id === id),
                        //         qty: '',
                        //         amount: '0.00'
                        //     };
                        // }
                    }
                    else if (name === 'rate') {
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            const totalAmount = Calculation.GetPurchaseBillItemAmount(selectedItem.qty, value, selectedItem.Taxrate);
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                rate: value === "0" ? '' : value,
                                amount: totalAmount.toString()
                            };
                        }
                        // else {
                        //     // If the selected item is not found, reset the properties
                        //     updatedItem = {
                        //         ...itemList.find((item) => item.id === id),
                        //         rate: '',
                        //         amount: '0.00'
                        //     };
                        // }
                    }
                    else if (name === 'Taxrate') {
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            const totalAmount = Calculation.GetPurchaseBillItemAmount(selectedItem.qty, selectedItem.rate, value);
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                Taxrate: value === "0" ? '' : value,
                                amount: totalAmount.toString()
                            };
                        }
                        // else {
                        //     // If the selected item is not found, reset the properties
                        //     updatedItem = {
                        //         ...itemList.find((item) => item.id === id),
                        //         Taxrate: '',
                        //         amount: '0.00'
                        //     };
                        // }
                    }
                    else {
                        // For other fields, just update the value
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            [name]: value,
                        };
                    }
                    // Update the itemList state with the updated item
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
                else {
                    if (name === 'qty') {
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            qty: '',
                            amount: '0.00'
                        };
                    }
                    else if (name === 'rate') {
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            rate: '',
                            amount: '0.00'
                        };
                    }
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Reset
    const handleReset = () => {
        setPurchaseOrder(purchaseOrderInitial);
        setPurchaseOrderError(purchaseOrderErrorInitial);
        setItemList(itemListInitial);
        setItemListError(itemListErrorInitial);
        setIsEdit(false);
        setEditData(null);
        setPurchaseOrderNo(false);
    }

    // Save
    const handleSubmit = async () => {
        try {
            dispatch(triggerLoader());
            const purchaseOrderErrors = await validatePurchaseOrder();
            const itemListErrors = await validateItemList();
            if (Object.keys(purchaseOrderErrors).length > 0 || Object.keys(itemListErrors).length > 0) {
                return false;
            }

            purchaseOrder.total_amount = totalAmount;
            purchaseOrder.item = await handlePurchaseOrderItemList();
            purchaseOrder.order_type = 'open';
            if (!isEdit) {
                const res = await configServ.createPurchaseOrder(purchaseOrder);
                if (res.status === 200) {
                    handleReset();
                    setIsChanged(!isChanged); handleOpenDialog('Success', res.message || 'Purchase Order created successfully.', 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to create purchase order.", 'error');
                }
            }
            else {
                const res = await configServ.editPurchaseOrder(purchaseOrder);
                if (res.status === 200) {
                    handleReset();
                    setIsChanged(!isChanged); handleOpenDialog('Success', res.message || 'Purchase Order updated successfully.', 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update purchase order.", 'error');
                }
            }
        }
        catch (error) {
            console.log(error);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handlePurchaseOrderItemList = async () => {
        try {
            const purchaseOrderItems = itemList.map((item) => ({
                itemname: item.itemname,
                itemid: item.itemid.toString(),
                itemcode: item.itemcode,
                packagingunit: item.packagingunit,
                hsn_code: item.hsn_code,
                Taxrate: item.Taxrate || '',
                qty: item.qty,
                rate: item.rate,
                amount: item.amount
            }));
            return purchaseOrderItems;
        }
        catch (error) {
            console.log(error);
        }
    }

    // Validate the Purchase Order fields
    const validatePurchaseOrder = async() => {
        const errors = {};

        // Check if the required fields are empty
        if (!purchaseOrder.partyname) {
            errors.partyname = 'Party Name is required.';
        }
        if (!purchaseOrder.billtoaddress) {
            errors.billtoaddress = 'Bill To Address is required.';
        }
        if (!purchaseOrder.delivery_date) {
            errors.delivery_date = 'Delivery Date is required.';
        }        
        if(!autoPurchaseOrderNo && !purchaseOrderNo && !isEdit){
            errors.purchase_order_no = "required";
        }
        if (!purchaseOrder.salesperson) {
            errors.salesperson = 'Salesperson is required.';
        }

        setPurchaseOrderError(errors);

        return errors;
    };

    // Validate ItemList
    const validateItemList = async() => {
        const errors = itemList.map(item => ({
            itemname: !item.itemname ? 'Item Name is required.' : '',
            qty: !item.qty ? 'Quantity is required.' : '',
            rate: !item.rate ? 'Rate is required.' : '',
            // Add additional validations for other item fields as needed...
        }));

        setItemListError(errors);
        const hasErrors = errors.some(error => error.itemname || error.qty || error.rate);
        return hasErrors ? errors : {};
    };

    useEffect(() => {
        if (editData !== null) {
            setPurchaseOrder(editData);
            setItemList(editData.item);
            setIsEdit(true);
            setIsCreate(true);
        }
    }, [editData])

    const handlePopup = () => {
        try {
            setIsCreate(!isCreate);
            handleReset();
        }
        catch (error) {
            console.log(error);
        }
    }
    
  const checkPurchaseOrderNo = async() => {
    try{
      const dataToSend = {
        purchase_order_no: purchaseOrder.purchase_order_no,
      }
      const res = await configServ.checkPurchaseOrderNo(dataToSend);
      if(res.status === 200){
        setPurchaseOrderNo(true);
      }
      else{
        setPurchaseOrderNo(false);
      }
    }
    catch(e){
      console.log(e);
    }
  }

  useEffect(()=>{
    if(purchaseOrder.purchase_order_no){
        checkPurchaseOrderNo();
    }
  },[purchaseOrder.purchase_order_no]);
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <CardHeadMenu
                prefix={"Na"}
                suffix={"Na"}
                title={"PURCHASE ORDER"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {isCreate && <>
                <motion.div
                    style={{
                        width: '100%',
                        backgroundColor: '#e3f3fc',
                        padding: 20,
                        borderRadius: '10px',
                        border: 'none',
                        margin: '10px',
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <Grid container alignItems="center" justifyContent="flex-start" style={{ padding: 10 }} spacing={1} >
                        <Grid container alignItems={'flex-end'} mx={1}>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel disabled={isEdit} control={<Checkbox checked={autoPurchaseOrderNo} onClick={(e) => setAutoPurchaseOrderNo(!autoPurchaseOrderNo)} />} label="Auto Order No" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Link to='/supplier'>
                                    <Tooltip title={'Add Supplier'}>
                                        <PersonAddIcon sx={{ cursor: 'pointer', color: 'white', backgroundColor: 'grey', margin: '5px' }} justifyContent="flex-end" />
                                    </Tooltip>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                disabled={autoPurchaseOrderNo}
                                label="Purchase Order No"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='purchase_order_no'
                                value={purchaseOrder.purchase_order_no}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {autoPurchaseOrderNo || purchaseOrderNo || isEdit ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                                      </InputAdornment>
                                    )
                                  }}
                                error={!!purchaseOrderError.purchase_order_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="grouped-demo"
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                value={purchaseOrder.partyname}
                                options={suppliers.map((option) => option.fname)}
                                onChange={handleSupplierAutocompleteChange}
                                renderInput={(params) => <TextField {...params}
                                    value={purchaseOrder.partyname}
                                    label="Supplier Name"
                                    fullWidth
                                    onChange={handleSupplierInputChange}
                                    error={!!purchaseOrderError.partyname}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Bill To Address"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='billtoaddress'
                                value={purchaseOrder.billtoaddress}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                error={!!purchaseOrderError.billtoaddress}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={3} md={3}>
                            <FormControl fullWidth size='small' >
                                <InputLabel id="demo-simple-select-label">Ship To Address</InputLabel>
                                <Select
                                    sx={{ backgroundColor: 'white' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Ship To Address"
                                    name='ShipToAddress'
                                    value={purchaseOrder.ShipToAddress}
                                    onChange={handlePurchaseOrderCustomer}
                                    error={!!purchaseOrderError.ShipToAddress}
                                >
                                    <MenuItem value={purchaseOrder.ShipToAddress}>{purchaseOrder.ShipToAddress}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                label="Purchase Order No"
                                placeholder='PON0001 (Sample)'
                                sx={{ backgroundColor: 'white' }}
                                name='purchase_order_no'
                                value={purchaseOrder.purchase_order_no}
                                onChange={handlePurchaseOrderCustomer}
                                error={!!purchaseOrderError.purchase_order_no}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Delivery Date"
                                variant="outlined"
                                type="date"
                                fullWidth
                                size='small'
                                name='delivery_date'
                                value={purchaseOrder.delivery_date}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                InputLabelProps={{ shrink: true, }}
                                error={!!purchaseOrderError.delivery_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Salesperson Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='salesperson'
                                value={purchaseOrder.salesperson}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                error={!!purchaseOrderError.salesperson}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Pay Terms"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='pay_terms'
                                value={purchaseOrder.pay_terms}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Remarks"
                                variant="outlined"
                                placeholder="Leave a comment here"
                                multiline
                                rows={2}
                                fullWidth
                                size='small'
                                name='remarks'
                                value={purchaseOrder.remarks}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" sx={{ color: 'black' }}>Order Type</FormLabel>
                                <RadioGroup name="use-radio-group" row value={purchaseOrder.order_type} >
                                    <FormControlLabel value="open" label="open" control={<Radio size="small"
                                        onClick={() => setPurchaseOrder((prev) => ({
                                            ...prev,
                                            'order_type': 'open'
                                        }))}
                                    />} />
                                    <FormControlLabel value="close" label="close" control={<Radio size="small"
                                        onClick={() => setPurchaseOrder((prev) => ({
                                            ...prev,
                                            'order_type': 'close'
                                        }))}
                                    />} />
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={3} md={3}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Approval Required"
                                checked={approval}
                                onChange={handleApprovalChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography variant="body1" >Net Amount(₹ {totalAmount})</Typography>
                        </Grid>
                    </Grid>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        spacing={3}
                        px={3}
                    >
                        <Button
                            variant="contained"
                            color={'secondary'}
                            onClick={handleReset}
                            startIcon={<RotateLeftIcon />}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="contained"
                            color={isEdit ? "success" : "primary"}
                            onClick={handleSubmit}
                            startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                        >
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                    </Stack>
                </motion.div>

                <AddPurchaseOrderItems
                    itemList={itemList}
                    handleAutocompleteChange={handleAutocompleteChange}
                    handleInputChange={handleInputChange}
                    handleAddItem={handleAddItem}
                    handleDeleteItem={handleDeleteItem}
                    items={items}
                    handleQuantityChange={handleQuantityChange}
                    errors={itemListError}
                />
            </>}
        </>
    );
}

export default memo(PurchaseOrderCard);
