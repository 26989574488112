import React, { memo, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Grid,
    Typography,
    NativeSelect,
    InputLabel,
    FormControl,
    CircularProgress,
    Stack,
    Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import configServ from '../../../services/config';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';
import AddLineItem from '../../masterEntry/ItemEditOptions/AddLineItem';
import useDebounce from '../../hooks/useDebounce';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchBox from '../../Search/SearchBox';
import ItemLedgerPopup from '../../Inventory/ItemLedgerPopup';
import { useLocation } from 'react-router-dom';

//FUNCTION
const ItemTable = ({ handleEdit, isChanged, pagination = true }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [itemList, setItemList] = useState([]);
    //const [filterItemList, setFilterItemList] = useState([]);
    const [searchBy, setSearchBy] = useState('item_name');
    const [search, setSearch] = useState(null);
    const debounceSearch = useDebounce(search, 1000);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    // const [openGenerateLineItems, setOpenGenerateLineItems] = useState(false);
    // const [lineItem, setLineItem] = useState({});
    const [openItemLedger, setOpenItemLedger] = useState(false);
    const [itemLedger, setItemLedger] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemLedgerLoading, setItemLedgerLoading] = useState(true);

    const location = useLocation();
    const [redirectItemName, setRedirectItemName] = useState(location.state);

    useEffect(() => {
        if (redirectItemName) {
            setSearchBy('item_name');
            setSearch(redirectItemName);
            fetchItemList('item_name', redirectItemName);
        }
        else {
            setSearchBy('item_name');
            setSearch('');
        }
    }, [redirectItemName]);

    useEffect(() => {
        if (!redirectItemName) {
            fetchItemList();
        }
    }, [isChanged, page, rowsPerPage]);

    useEffect(() => {
        if (!redirectItemName) {
            if (page === 0) {
                fetchItemList();
            }
            else {
                setPage(0);
            }
        }
    }, [debounceSearch]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const fetchItemList = async (searchByItem = null, ItemName = null) => {
        try {
            setLoading(true);
            const dataToSend = {
                "company_id": company_id,
                "search_by": searchByItem ? searchByItem : searchBy,
                "search": ItemName ? ItemName : debounceSearch,
                "per_page": rowsPerPage,
                "page": page
            }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                if (result.length > 0) {
                    setItemList(result);
                    setCount(res.total);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const headers = ['ITEM NAME', 'ITEM CODE', 'UNIT', 'ACTUAL QTY', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    // const paginatedData = pagination
    //     ? filterItemList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //     : filterItemList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5', // Set the header background color to blue
        color: 'white',
    };

    const cellStyle = {
        py: 0,
        my: 0,
    }

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    // const FetchLocationName = ({ id }) => {
    //     const [loc, setLoc] = useState()

    //     useEffect(() => {
    //         if (id !== 0) {
    //             locName()
    //         }
    //     }, [])

    //     const locName = async () => {
    //         try {
    //             const result = await configServ.getLocationById({ "id": id })
    //             setLoc(result.location_name)
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }

    //     return (
    //         <TableCell>{loc || 'N/A'}</TableCell>
    //     )
    // }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleItemLedger = async (params) => {
        try {
            setOpenItemLedger(true);
            const item = itemList.find(x => x.id === params);
            const dataToSend = {
                item_id: params,
                //per_page: 10,
                //page: 0
            }
            const res = await configServ.getItemLedger(dataToSend);
            if (res.status === 200) {
                setItemLedger(res.data);
                setSelectedItem(item);
            }
            else {
                setItemLedger([]);
                setSelectedItem(null);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setItemLedgerLoading(false);
        }
    }

    const handleItemLedgerClose = () => {
        setOpenItemLedger(false);
        setItemLedger([]);
        setSelectedItem(null);
        setItemLedgerLoading(true);
    }

    // const handleOpenLineItems = () => {
    //     setOpenGenerateLineItems(true);
    // };

    // const handleCloseLineItems = () => {
    //     setOpenGenerateLineItems(false);
    // };

    // const handleLineItems = (params) => {
    //     try {
    //         setLineItem(params);
    //         handleOpenLineItems();
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    //#endregion

    return (
        <>
            <ItemLedgerPopup openItemLedger={openItemLedger} handleItemLedgerClose={handleItemLedgerClose} itemLedger={itemLedger} selectedItem={selectedItem} itemLedgerLoading={itemLedgerLoading} />

            {/* <AddLineItem itemData={lineItem} handleClose={handleCloseLineItems} open={openGenerateLineItems} /> */}
            <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ pt: 1 }} spacing={1} direction={'row'}>
                <Grid item xs={12} md={6} ml={0} justifyContent={'flex-start'}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Item List: {count}</Typography>
                </Grid>
                {
                    redirectItemName ?
                        <>
                            <Grid container item xs={12} md={6} justifyContent={'flex-end'}>
                                <Button variant='contained' title='Referesh' color='success' startIcon={<RefreshIcon sx={{ ml: 1, color: 'white' }} />}
                                    onClick={() => {
                                        setRedirectItemName(null);
                                    }}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} md={3}>
                                <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Search By
                                        </InputLabel>
                                        <NativeSelect
                                            defaultValue={'contact_name'}
                                            onChange={handleSearchBy}
                                            inputProps={{
                                                name: 'searchBy',
                                                id: 'uncontrolled-native',
                                            }}
                                        >
                                            <option value={'item_name'}>Item Name</option>
                                            <option value={'item_code'}>Item Code</option>
                                            <option value={'location_name'}>Location</option>
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
                                <SearchBox search={search} setSearch={setSearch} />
                            </Grid>
                        </>
                }
            </Grid>

            <TableContainer component={Paper} sx={{ py: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((header, index) => (
                                <TableCell key={index} sx={headerCellStyle}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {
                        loading === true ?
                            <TableRow>
                                <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                    <Box>
                                        <CircularProgress />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            :
                            <TableBody>
                                {
                                    itemList.length > 0 ?
                                        itemList.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}
                                                sx={{
                                                    ...rowStyle,
                                                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                                }}>
                                                <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                                <TableCell sx={cellStyle}>{row.item_name}</TableCell>
                                                <TableCell sx={cellStyle}>{row.item_code}</TableCell>
                                                {/* <FetchLocationName id={row.location_id} /> */}
                                                <TableCell sx={cellStyle}>{row.unit}</TableCell>
                                                <TableCell sx={cellStyle}>{row.actualquantity}</TableCell>
                                                {/* <TableCell sx={cellStyle}>{row.costprice}</TableCell> */}
                                                <TableCell sx={cellStyle}>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                                <TableCell sx={cellStyle}>
                                                    <Stack direction={'row'}>
                                                        <EditIcon sx={{
                                                            borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                                            padding: 0, m: 0.5, ":hover": { backgroundColor: '#99999955' }
                                                        }} color='primary' onClick={() => { handleEdit(row); scrollToTop() }}
                                                        />
                                                        <VisibilityIcon sx={{
                                                            borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                                                            padding: 0, m: 0.5, ":hover": { backgroundColor: '#99999955' }
                                                        }} color='primary' onClick={() => handleItemLedger(row.id)}
                                                        />
                                                        {/* <Tooltip title='Generate Line Item' placement='top' arrow>
                                                                <IconButton onClick={()=>handleLineItems(row)} sx={{'&hover':{backgroundColor:'#99999955'}}}>
                                                                <Inventory2Icon color='secondary'/>
                                                                </IconButton>
                                                            </Tooltip> */}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                                <Box sx={{ color: 'red', fontWeight: 600 }}>
                                                    No data found.
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 1 * emptyRows }}>
                                        <TableCell colSpan={headers.length + 2} />
                                    </TableRow>
                                )}
                            </TableBody>
                    }
                </Table>
                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        </>
    );

};

export default memo(ItemTable);
