import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material'
import { motion } from 'framer-motion';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContactsIcon from '@mui/icons-material/Contacts';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useDispatch, useSelector } from 'react-redux';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import Constants from '../../utility/Constants';
import CardHeadMenu from '../../Components/Card/CardHeadMenu/CardHeadMenu';
import configServ from '../../services/config';
import CustomerContact from '../../Components/Customers/CustomerContact';

//FUNCTION
const CustomerCard = ({ open, isChanged, setIsChanged, editData, setEditData, }) => {
  //#region code
  const dispatch = useDispatch();
  // const app = useSelector((state) => state.GlobalVariables.noRoomService);
  // const { company_id } = useSelector((state) => state.GlobalVariables);
  const { user_id, company_id, companyCountry } = useSelector((state) => state.GlobalVariables);

  const initialFormData = {
    openingamount: 0,
    type: 'Standard',
    country: companyCountry,
    status: true,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openCustContact, setOpenCustContact] = useState(false);
  const [isCustomerName, setIsCustomerName] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    //Update form data
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prevValidationError) => ({
        ...prevValidationError,
        [name]: '',
      }));
    }
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const assignContactName = () => {
    if (!isDone) {
      setFormData((state) => ({
        ...state,
        contact_name: formData.fname || "",
      }));
    }
  };

  const handleSubmit = async () => {
    //e.preventDefault();
    const dataToSend = {
      ...formData,
      company_id: company_id,
      user_id: user_id,
    };
    if (Boolean(formData.is_credit_days) === false) {
      delete dataToSend.credit_period;
      delete dataToSend.credit_limit;
    }

    try {
      const error = handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});
      dispatch(triggerLoader);
      if (!isEdit) {
        const result = await configServ.addCustomer(dataToSend);
        if (result.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', result.message || 'Customer created successfully.', 'success');
        }
        else if (result.status === 400) {
          handleOpenDialog('Warning', result.message || 'Failed to add customer.', 'warning');
        }
        else {
          handleOpenDialog('Error', 'Failed to add customer.', 'error');
        }
      }
      else {
        const result = await configServ.editCustomer(dataToSend);
        if (result.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', result.message, 'success');
        }
        else {
          handleOpenDialog('Error', 'Failed to update customer.', 'error');
        }
      }
    }
    catch (err) {
      console.log(err);
      handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
    }
    finally {
      dispatch(triggerLoader);
    }
  };

  const fetchCountries = async () => {
    try {
      const res = await configServ.getCountry();
      if (res.status === 200) {
        setCountryList(res.data);
      }
      else {
        setCountryList([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.country && countryList.length > 0) {
      fetchStates();
    }
    else {
      fetchCountries();
    }
  }, [formData.country, countryList]);

  const fetchStates = async () => {
    try {
      const countryId = countryList.find(x => x.country === formData.country).PK;
      const res = await configServ.getState({ "country": countryId });
      if (res.status === 200) {
        setStateList(res.data);
      }
      else {
        setStateList([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (editData !== null) {
      setFormData(editData);
      setIsEdit(true);
      setOpenDialog(true);
    }
  }, [editData]);

  const clear = () => {
    setFormData(initialFormData);
    setEditData(null);
    setIsDone(false);
    setIsEdit(false);
    setValidationErrors({});
  };

  const handleCustContactOpen = () => {
    setOpenCustContact(true);
  };
  const handleCustContactClose = () => {
    setOpenCustContact(false);
  };

  // Regular expression pattern for validating email addresses
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleFormValidation = () => {
    const errors = {};
    if (!formData.fname) {
      errors.fname = "required";
    }
    else if (!isCustomerName && !isEdit) {
      errors.fname = "required";
    }
    if (!formData.contact_name) {
      errors.contact_name = "required";
    }
    if (!formData.mobile) {
      errors.mobile = "required";
    }
    if (formData.email) {
      if (!emailPattern.test(formData.email)) {
        errors.email = "Invalid Email";
      }
    }
    if (!formData.address) {
      errors.address = "required";
    }
    if (!formData.country) {
      errors.country = "required";
    }
    if (!formData.State) {
      errors.State = "required";
    }
    // if (!formData.Pin) {
    //   errors.Pin = "required";
    // }
    if (!formData.type) {
      errors.type = "required";
    }
    if (!formData.openingamount) {
      errors.openingamount = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
    clear();
  };

  const checkCustomerName = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        fname: formData.fname,
      }
      const res = await configServ.checkCustomerName(dataToSend);
      if (res.status === 200) {
        setIsCustomerName(true);
      }
      else {
        setIsCustomerName(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (formData.fname) {
      checkCustomerName();
    }
    else {
      setIsCustomerName(false)
    }
  }, [formData.fname]);
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <CustomerContact
        handleClose={handleCustContactClose}
        open={openCustContact}
        customer_id={formData.id}
        customer_code={formData.customer_code}
      />

      {/* <motion.h4 style={{ textAlign: 'center' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
            >
                Add Category
            </motion.h4>
            <br /> */}

      <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'CUSTOMERS'} handlePopup={handleClickOpen} create={openDialog} />

      {openDialog && (
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            marginTop: 0,
            padding: 20,
            borderRadius: "10px",
            border: "none",
          }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Customer Detail
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Firm Name<span style={{ color: 'red' }}>*</span></span>}
                name="fname"
                onChange={handleChange}
                value={formData.fname || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isCustomerName || isEdit ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                    </InputAdornment>
                  )
                }}
                error={!!validationErrors.fname}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Contact Name<span style={{ color: 'red' }}>*</span></span>}
                name="contact_name"
                onChange={handleChange}
                onClick={() => {
                  setIsDone(true);
                  assignContactName();
                }}
                value={formData.contact_name || ""}
                error={!!validationErrors.contact_name}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Mobile Number<span style={{ color: 'red' }}>*</span></span>}
                name="mobile"
                type="number"
                onChange={handleChange}
                value={formData.mobile || ""}
                error={!!validationErrors.mobile}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Email</span>}
                name="email"
                onChange={handleChange}
                value={formData.email || ""}
                error={!!validationErrors.email}
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: 'red' }}>
                      {validationErrors.email}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Bill To Address<span style={{ color: 'red' }}>*</span></span>}
                name="address"
                onChange={handleChange}
                value={formData.address || ""}
                error={!!validationErrors.address}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.country}>Country<span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  required
                  name="country"
                  label="select"
                  value={formData.country || ""}
                  onChange={handleChange}
                  error={!!validationErrors.country}
                  sx={{ backgroundColor: "white" }}
                >
                  {countryList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.country}>
                        {item.country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.State}>State<span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  required
                  name="State"
                  label="select"
                  value={formData.State || ""}
                  onChange={handleChange}
                  error={!!validationErrors.State}
                  sx={{ backgroundColor: "white" }}
                >
                  {stateList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.State}>
                        {item.State}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Pin</span>}
                name="Pin"
                onChange={handleChange}
                value={formData.Pin || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel id="customer-type">Customer Type<span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  labelId="customer-type"
                  label="Customer Type"
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                  error={!!validationErrors.type}
                  sx={{ backgroundColor: "white" }}
                >
                  {
                    Constants.customerTypes.map((item, index) => (
                      <MenuItem key={index} value={item.value}>{item.label} </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Opening Amount<span style={{ color: 'red' }}>*</span></span>}
                name="openingamount"
                onChange={handleChange}
                value={formData.openingamount || ""}
                defaultValue={0}
                error={!!validationErrors.openingamount}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="PAN/NO"
                name="pan_no"
                onChange={handleChange}
                value={formData.pan_no || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="GSTIN/UIN"
                name="gstin"
                onChange={handleChange}
                value={formData.gstin || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <FormControlLabel
                control={<Checkbox />}
                name="is_credit_days"
                checked={Boolean(formData.is_credit_days)}
                onChange={handleChangeCheckbox}
                label="Check credit days"

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Period"
                size="small"
                type='number'
                name="credit_period"
                InputProps={{
                  endAdornment: "d",
                }}
                disabled={Boolean(formData.is_credit_days) === true ? false : true}
                onChange={handleChange}
                value={formData.credit_period || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Limit"
                size="small"
                type='number'
                name="credit_limit"
                disabled={Boolean(formData.is_credit_days) === true ? false : true}
                onChange={handleChange}
                value={formData.credit_limit || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} columnSpacing={0}>
                    <FormControlLabel 
                        control={<Checkbox />} 
                        name='isShipToAddressSame' 
                        checked={Boolean(isShipToAddressSame)} 
                        onChange={()=>setIsShipToAddressSame(!isShipToAddressSame)} 
                        label='Ship To Address same as Bill To Address'   
                        style={{fontWeight:'bolder'}}/>
                </Grid>*/}

            <Grid item xs={12} sm={4} md={4} columnSpacing={0}>
              <FormControlLabel
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
                sx={{ fontWeight: "bold" }}
              />
            </Grid>

            <Grid container spacing={2} justifyContent="space-between" >
              <Grid container item justifyContent={'center'} xs={12} md={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  hidden={isEdit ? false : true}
                  onClick={handleCustContactOpen}
                  startIcon={<ContactsIcon />}
                >
                  Add Other Contacts
                </Button>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                xs={12}
                md={6}
              >
                <Grid container justifyContent={'flex-end'} item xs={6} md={4}>
                  <Button
                    onClick={clear}
                    variant="contained"
                    color='secondary'
                    startIcon={<RotateLeftIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid container justifyContent={'flex-start'} item xs={6} md={4}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color={isEdit ? "success" : "primary"}
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </>
  );
};

export default CustomerCard;
