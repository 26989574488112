import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import configServ from '../../services/config';
import { useDispatch } from 'react-redux';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";

export default function InvoiceOrientation({ open, setOpen, data }) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [page, setPage] = useState(2);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const dispatch = useDispatch()

    const printInvoice = async (landscape) => {
        const dataToSend = {
            ...data,
            "landscape": landscape,
            "page": page
        }
        dispatch(triggerLoader());
        try {
            const result = await configServ.printInvoice(dataToSend);
            const blobFile = new Blob([result], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blobFile);
            window.open(url);
            dispatch(triggerLoader());
        } 
        catch (e) {
            handleOpenDialog('Error while printing invoice');
            console.log(e);
            dispatch(triggerLoader());
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePage = (event) => {
        const data = parseInt(event.target.value);
        setPage(data);
    }


    return (
        <div>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='body' fontSize={'large'}>Orientation</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#090e34' }}>No. of pages</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={page}
                                onChange={handlePage}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="1" />
                                <FormControlLabel value={2} control={<Radio />} label="2" />
                                <FormControlLabel value={3} control={<Radio />} label="3" />
                                <FormControlLabel value={4} control={<Radio />} label="4" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ color: '#090e34' }}>Choose an orientation to print</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant='contained' onClick={() => { handleClose(); printInvoice(true) }}>
                        Landscape
                    </Button>
                    <Button variant='outlined' onClick={() => { handleClose(); printInvoice(false) }} >
                        Portrait
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}